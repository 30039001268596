import React from "react";
import { Container } from "react-bootstrap";
import metaLogo from "../../asset/image/metaVerse.png";
// import metaVideo from "../../asset/image/loopableversions.mp4";
import "./MetaVerse.css";
import { Fade } from "react-reveal";

const MetaVerse = () => {
  return (
    <div className="metaverse" id="metaverse">
      <Container>
        <Fade bottom>
          <img className="mt-5 pt-5 img-fluid" src={metaLogo} alt="" />
        </Fade>
        <Fade bottom>
          <p className="metaHeading mt-4">
            Stageverse Exclusive Metaverse Club
          </p>
          <p className="ms-md-5 me-md-5 ps-md-5 pe-md-5 meta-description">
            After traveling to the different metaverse, we decided to build our
            first virtual world in Stageverse. Stageverse is developed by a Los
            Angeles metaverse technology company which is dedicated to closing
            the gap between the natural human experience of physical space and
            has unlimited creative and expressive potential, The main reason why
            we choose Stageverse is that they have a very beautiful design, with
            a smooth user experience, and are compatible with both mobile phones
            and VR devices. Our NFT holders can enter the club without cost,
            With real hired receptionists and weekly hold events; members can
            socialize and have fun freely in the club.
          </p>
        </Fade>
        <Fade bottom>
          <div className="metaVerseVideo">
            {/* <video width="90%" controls>
                    <source src="https://www.youtube.com/embed/7teB25DjciI" type="video/mp4"/>
  
                    Your browser does not support HTML video.
                </video> */}
            <embed
              className="metaVerseVide"
              src="https://www.youtube.com/embed/18XtkJDb3Ig"
              title="YouTube video player"
              frameborder="0"
              allow="autoplay"
              allowfullscreen
            ></embed>
          </div>
        </Fade>
      </Container>
    </div>
  );
};

export default MetaVerse;
