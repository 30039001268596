import React from 'react';
import Footer from '../../Shared/Footer/Footer';
import Artists,{Gallery} from '../Artists/Artists';
import Bonuse from '../Bonuse/Bonuse';
import GiveWay from '../GiveWay/GiveWay';
import MetaEvents from '../MetaEvents/MetaEvents';
import MetaVerse from '../MetaVerse/MetaVerse';
import OfflineEvents from '../OfflineEvents/OfflineEvents';
import OurTeam from '../OurTeam/OurTeam';
import Roadmap from '../Roadmap/Roadmap';
import TopBanner from '../TopBanner/TopBanner';
import Utilities from '../Utilities/Utilities';
import Voting from '../Voting/Voting';

const HomeParents = () => {
    return (
        <div>
            <TopBanner/>
            <Artists/>
            <Gallery/>
            <Utilities/>
            <MetaVerse/>
            <MetaEvents/>
            <GiveWay/>
            <OfflineEvents/>
            <Voting/>
            <Bonuse/>
            <OurTeam/>
            <Roadmap/>
            <Footer/>
        </div>
    );
};

export default HomeParents;