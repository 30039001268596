import { useState } from "react";
import "./Bonuse.css";
import logo from "../../asset/image/bonus-logo.png";
import img1 from "../../asset/image/b-img-1.png";
import img2 from "../../asset/image/b-img-2.png";
import img3 from "../../asset/image/b-img-3.png";
import { Container } from "react-bootstrap";
import { Fade } from "react-reveal";

const Bonuse = () => {
  const [showBonus, SetshowBonus] = useState(false);
  const ToggleBonusText = () => {
    SetshowBonus(!showBonus);
  };
  return (
    <div className="bonuse-section pb-5">
      <Container className="section-wraper pb-5">
        <Fade bottom>
          <div className="logo-part">
            <img src={logo} alt="" />
            <p className="giveWayHeading pt-4">SPECIAL BONUSES</p>
          </div>
        </Fade>

        <div className="bonuse-box mx-md-5" id="bonus">
          <Fade bottom>
            <div className="bonuse-content">
              <div className="pe-md-4 pt-5 left">
                <p className="bonus-title">
                  Staking FREEDEN NFTs will reward special bonuses.
                </p>
                {/* <p className={showBonus ? 'bonus-description show-bonus-text-active' : 'bonus-description show-bonus-text'}>The 2D and 3D lottery activities have different prizes, and the 3D lottery */}
                <p className={"bonus-description"}>
                  The 2D and 3D lottery activities have different prizes, and
                  the 3D lottery prizes will be better than the 2D ones.
                </p>
                {!showBonus ? (
                  <div className="bonus-learn-more">
                    <button
                      type="button"
                      className="learn-more"
                      onClick={ToggleBonusText}
                    >
                      Learn More
                      <i class="fa fa-chevron-down" aria-hidden="true"></i>
                    </button>
                  </div>
                ) : null}
              </div>
              <div className="ps-md-4 right">
                <img className="bonuse-img" src={img1} alt="" />
              </div>
            </div>
            {/* for desktop */}
            <div className="d-md-block d-none">
                  <div className="bonuse-content">
                    <div className="pe-md-4 left">
                      <img className="bonuse-img" src={img2} alt="" />
                      {/* <p>Mouse Pads / T-Shirts / Hoodies / Coaster / Tote Bag / Pillow</p> */}
                    </div>
                    <div className="ps-md-4 right">
                      <p className="bonus-title">Two NFT holders</p>
                      <p className="bonus-description">
                        1. You will get limited exclusive NFT accessories,
                        giving NFTs a unique and rare appearance. <br /> <br />
                        2. You can choose two from the following six options as
                        souvenirs; Mouse Pads / T-Shirts / Hoodies / Coaster /
                        Tote Bag / Pillow We will take care of the delivery; the
                        recipient must pay the shipping fee.
                        {/* <p>Souvenirs: Holders with two NFTs can choose two out of five tangible products (as above);
                        FreEden will take care of the delivery, and the buyers have to cover the shipping charges.</p> */}
                      </p>
                    </div>
                  </div>

                  <div className="bonuse-content">
                    <div className="pe-md-4 pt-5 left">
                      <p className="bonus-title">Three NFT Holders</p>
                      <p className="bonus-description">
                        1. You will get limited exclusive NFT accessories,
                        giving NFTs a unique and rare appearance. <br />
                        2. You can choose five from the following six options as
                        souvenirs; Mouse Pads / T-Shirts / Hoodies / Coaster /
                        Tote Bag / Pillow We will take care of the delivery; the
                        recipient must pay the shipping fee. <br /> <br />
                        <span className="fs-5">Weekly sweepstakes:</span>{" "}
                        Holders with more FREEDEN NFTs have higher winning
                        chances.
                        <br /> <br />
                        <span className="fs-5"> Prizes:</span> <br />
                        Crypto Currencies: Stable coin / ETH / BNB / Altcoins<br/>
                        Digital gift Cards: Amazon/Netflix/Steam etc Tangible<br/>
                        Gifts: Mousepad/Power Bank/Hoody/T-Shirt/Cap<br/>
                      </p>
                    </div>
                    <div className="ps-md-4 right">
                      <img className="bonuse-img" src={img3} alt="" />
                    </div>
                  </div>
            </div>
            {/* for mobile */}
            <div className="d-md-none d-block">
              {showBonus ? (
                <>
                  <div className="bonuse-content">
                    <div className="pe-md-4 left">
                      <img className="bonuse-img" src={img2} alt="" />
                      {/* <p>Mouse Pads / T-Shirts / Hoodies / Coaster / Tote Bag / Pillow</p> */}
                    </div>
                    <div className="ps-md-4 right">
                      <p className="bonus-title">Two NFT holders</p>
                      <p className="bonus-description">
                        1. You will get limited exclusive NFT accessories,
                        giving NFTs a unique and rare appearance. <br /> <br />
                        2. You can choose two from the following six options as
                        souvenirs; Mouse Pads / T-Shirts / Hoodies / Coaster /
                        Tote Bag / Pillow We will take care of the delivery; the
                        recipient must pay the shipping fee.
                        {/* <p>Souvenirs: Holders with two NFTs can choose two out of five tangible products (as above);
                        FreEden will take care of the delivery, and the buyers have to cover the shipping charges.</p> */}
                      </p>
                    </div>
                  </div>

                  <div className="bonuse-content">
                    <div className="pe-md-4 pt-5 left">
                      <p className="bonus-title">Three NFT Holders</p>
                      <p className="bonus-description">
                        1. You will get limited exclusive NFT accessories,
                        giving NFTs a unique and rare appearance. <br />
                        2. You can choose five from the following six options as
                        souvenirs; Mouse Pads / T-Shirts / Hoodies / Coaster /
                        Tote Bag / Pillow We will take care of the delivery; the
                        recipient must pay the shipping fee. <br /> <br />
                        <span className="fs-5">Weekly sweepstakes:</span>{" "}
                        Holders with more FREEDEN NFTs have higher winning
                        chances.
                        <br /> <br />
                        <span className="fs-5"> Prizes:</span> <br />
                        Crypto Currencies: Stable coin / ETH / BNB / Altcoins<br/>
                        Digital gift Cards: Amazon/Netflix/Steam etc Tangible<br/>
                        Gifts: Mousepad/Power Bank/Hoody/T-Shirt/Cap<br/>
                      </p>
                    </div>
                    <div className="ps-md-4 right">
                      <img className="bonuse-img" src={img3} alt="" />
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </Fade>
        </div>
      </Container>
      {/* <a href="#ourTeam"><button className='scrollTop mt-3'  style={{position:"relative",zIndex:"2"}}>↡</button></a> */}
    </div>
  );
};

export default Bonuse;
