import { useState } from "react";
import { Container } from "react-bootstrap";
import banner from "../../Component/asset/image/metaverseBeg.jpg";
import Navigation2 from "../Shared/Navigation2/Navigation2";
import img1 from "../asset/mint/begLt.png";
import img2 from "../asset/mint/begRt.png";
import img3 from "../asset/mint/begLb.png";
import img4 from "../asset/mint/begRb.png";
import circle from "../asset/mint/mint.gif";
// import circle from "../asset/mint/circle.png";
import endImg from "../asset/mint/desEnd.png";
import banner1 from "../asset/mint/story1.jpg";
import banner2 from "../asset/mint/fighting-angels-w.jpg";
import banner3 from "../asset/mint/freeden-new-world-w.jpg";
import "./Mint.css";
import Footer from "../Shared/Footer/Footer";

const MintNft = () => {
  const [showButton, SetshowButton] = useState(false);
  const [mintCount, setMintCount] = useState(1);

  const handleMintCount = (type) => {
    switch (type) {
      case "+":
        setMintCount(mintCount + 1);
        break;
      case "-":
        if (mintCount - 1 > 1) {
          setMintCount(mintCount - 1);
        } else {
          setMintCount(1);
        }
        break;
      default:
        return mintCount;
    }
  };
  return (
    <div>
      {/* <div className='banner' id='home'> */}
      <div className="navigation-position">
        <Navigation2 />
      </div>
      <div className="mint-banner-background-img"></div>
      {/* <img className='deskTwo-banner' src={banner} alt=""/> */}
      {/* </div> */}
      <div className="desktop-three-back">
        <Container>
          <div className="desk-3">
            <div className="minit-res">
              {/* <p>
                    Thousands of years ago, before the peace that we know today, the world knew of chaos. It
                    ripped through society, crumbling structures and decaying peace with little resistance. The
                    world’s only hope was a group of wise and powerful Sages. Blessed with divine power, the sages came together to cast out the chaos, banishing it from
                    our mortal plane. In order to prevent the resurrection of this evil, they created a powerful
                    rune that would be called upon if evil ever showed its face again.
                    Now it is happening again… Chaotic energy has seeped its way into the world once more,
                    and the holy rune has been deployed to save humanity. Split into five thousand sealed
                    genesis bondings, the rune’s mission is to find those not yet tainted by chaos and save
                    them. Once a bonding has been successfully initiated, a guardian angel is deployed to watch over
                    the individual that has been blessed with it. They must be guided to a new world, one where
                    they can be safe forever. They must find FreEden FREEDEN is a holy realm whose strength and protection from chaos come with each
                    successful bond finding its way there. Therefore, each of the five thousand bonds must find
                    their way to this holy realm, to restore the strength of the people, and invoke the true power
                    of the guardian angels.
                    </p> */}

              {/* border images */}
              <img className="corner1" src={img1} alt="" />
              <img className="corner2" src={img2} alt="" />
              <img className="corner3" src={img3} alt="" />
              <img className="corner4" src={img4} alt="" />

              {/*  */}
              <div className="new-story-section">
                <div className="mb-md-5 mb-3">
                  <img className="img-fluid" src={banner1} alt="story one" />
                </div>
                <p>
                  Back in time, too long ago for anyone to truly remember it,
                  Darkness threatened to consume the World as we know it. It
                  tore into the ground, ripped mountains apart: and destroyed
                  those who dared stand up against it. With seas and rivers
                  drying out, a slow but humiliating defeat seemed to be the
                  future of humanity. Until the appearance of the Sages. Ten
                  Saints who had been born to save the Universe when the time
                  came.
                </p>

                <div className="my-4 text-center">
                  {showButton ? null : (
                    <button
                      type="button"
                      className="learn-more mx-auto"
                      onClick={() => SetshowButton(true)}
                    >
                      Learn More{" "}
                      <i class="fa fa-chevron-down" aria-hidden="true"></i>
                    </button>
                  )}
                </div>
                {showButton && (
                  <>
                    <div className="my-md-5 my-3">
                      <img className="img-fluid" src={banner2} alt="" />
                    </div>
                    <p>
                      But little did they know that their efforts were but
                      temporary. After being prevented from taking over the
                      World, Darkness reared its ugly head once more. Chaos
                      brewed in the air as clouds darkened until the sky was
                      pitch-black, as a freezing cold wind swept away spring
                      flowers. The Rune, this time, did not prove enough to
                      capture the evil powers that came to destroy the Universe.
                      Even with the unlimited magic coming from the Sages,
                      instead of creating a shield, it shattered into six
                      thousand genesis bondings, which would become the last
                      hope of humanity. Protecting the World that has fallen
                      apart is useless now, so the bondings’ duty is simple:
                      rescue and save those few who have yet to be swallowed by
                      the Darkness. As the Skies fear the destruction just as
                      badly as the rest of the World, they are ready to deploy
                      their most skilled Angels to protect and lead the 6000
                      Pure Souls into the last remaining safe Land: FREEDEN.
                      After all, only those who have successfully completed the
                      bonding contract can see these Angels, for all other souls
                      have withered away.
                    </p>
                    <div className="my-md-5 my-3">
                      <img className="img-fluid" src={banner3} alt="" />
                    </div>
                    <p>
                      Blessed with divine power, the sages came together to cast
                      out the chaos, banishing it from our mortal plane. To
                      prevent the resurrection of this evil, they created a
                      powerful rune that would be called upon if evil ever
                      showed its face again. Now it is happening again… Chaotic
                      energy has seeped into the world once more, and the holy
                      rune has been deployed to save humanity. Split into five
                      thousand sealed genesis bondings, the rune’s mission is to
                      find those not yet tainted by chaos and save them. Once
                      bonding has been successfully initiated, a guardian angel
                      is deployed to watch over the individual that has been
                      blessed with it. They must be guided to a new world, one
                      where they can be safe forever. They must find FREEDEN.
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="pt-5 mt-2 img-container">
            <h2 className="text_pr">MINT RESULT REVEALING SECTION</h2>
            <img className="img-fluid circle-img" src={circle} alt="" />
            <div className="row my-md-4 my-1">
              <button
                onClick={() => handleMintCount("-")}
                className="trianglebtnLeft"
              ></button>
              <div className="mint_count_box d-flex justify-content-center align-items-center">
                {mintCount}
              </div>
              <button
                onClick={() => handleMintCount("+")}
                className="trianglebtnRight"
              ></button>
            </div>
            <img className="img-fluid endImg" src={endImg} alt="" />
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default MintNft;
