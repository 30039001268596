import { useState, useRef, useEffect } from "react";
// import artistCard from "../../asset/artist/artistCard.png";
import artistCard2 from "../../asset/artist/artistCard2.jpg";
import artistJapan from "../../asset/artist/artistJapan.jpg";
import airroolChina from "../../asset/artist/airroolChina.jpg";
import demaTaiwan from "../../asset/artist/demaTaiwan.jpg";
import eichiKP from "../../asset/artist/eichiKP.jpg";
import evaUkraine from "../../asset/artist/evaUkraine.jpg";
import zeronextTaiwan from "../../asset/artist/zeronextTaiwan.jpg";
import nagiImg from "../../asset/artist/nagi.jpg";
import 倉科 from "../../asset/artist/倉科.jpg";
import 四葉 from "../../asset/artist/四葉.jpg";
// import slideImgRound from "../../asset/artist/roundSlider.png";
import ArrowDown from "../../../Component/asset/image/arrow-down.png";
import ArtistContentImgT from "../../../Component/asset/image/artist-drop-img-top.png";
import ArtistContentImgB from "../../../Component/asset/image/artist-img-drop-btm.png";
import pixivIcon from "../../../Component/asset/image/icons/pixiv.svg";
//
import arrowRight from "../../asset/artist/rightbtn.png";
import arrowLeft from "../../asset/artist/leftbtn.png";
//
import eichi_human from "../../asset/gallery/eichi_human.png";
// import art_2 from "../../asset/gallery/art_2.png";
// import art_3 from "../../asset/gallery/art_3.png";
// import art_4 from "../../asset/gallery/art_4.png";
import angel_neko from "../../asset/gallery/angel_neko.png";
import kitten_dema from "../../asset/gallery/kitten_dema.png";
import demon_airool from "../../asset/gallery/demon_airool.png";
import fairy_eva from "../../asset/gallery/fairy_eva.png";
// import art_9 from "../../asset/gallery/art_9.png";
// import art_10 from "../../asset/gallery/art_10.png";
// 
import none_img from "../../asset/gallery/none.png";
// import aboutUsArt from "../../asset/image/artist.png";
// import raseImg from "../../asset/gallery/rase.svg";

import aboutUsArtMobile from "../../asset/image/artist_mobile_head.png";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, EffectCoverflow } from "swiper";
import Flag from "react-world-flags";
import "./Artists.css";
import { Fade } from "react-reveal";

const Artists = () => {
  const [swiper, setSwiper] = useState();
  const prevRef = useRef();
  const nextRef = useRef();

  useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  return (
    <div className="artist-body" id="artist">
      <img
        src={aboutUsArtMobile}
        className="artist_heading d-md-none mx-auto mb-5 w-100 d-block"
        alt=""
      />
      {/* <img src={aboutUsArt} className="artist_heading d-md-block d-none" alt="" /> */}

      <Fade bottom>
        <Swiper
          modules={[Navigation]}
          slidesPerView={4}
          spaceBetween={10}
          // navigation={true}
          navigation={{
            prevEl: prevRef?.current,
            nextEl: nextRef?.current,
          }}
          loop={true}
          // initialSlide={
          breakpoints={{
            270: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            //   30: {
            //     slidesPerView: 2,
            //     spaceBetween: 14,
            //   },
            // 47: {
            //     slidesPerView: 3,
            //     spaceBetween: 20,
            //   },
            768: {
              slidesPerView: 3,
              spaceBetween: 35,
            },
            1024: {
              slidesPerView: 4,
            },
            1524: {
              slidesPerView: 5,
            },
            1924: {
              slidesPerView: 6,
            },
          }}
          onSwiper={setSwiper}
          className="mySwiper"
        >
          {/* 1 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "けだま",
                img: artistJapan,
                country: "Japan",
                code: "JP",
                race: "Angel",
                body: `
                I'm a professional illustrator who also has to take care of my family and children. Most of my artworks are fanarts, but I would like to try more possibilities if there's a chance.
                Therefore, I'm glad that I can participate in this project. Although this is a brand new challenge for me, I do my best to learn new things and unleash my potential to make beautiful artwork to help this project succeed.
              `,
                pixiv: `https://www.pixiv.net/users/32304575`,
                twit: `https://twitter.com/mqq11`,
              }}
            />
          </SwiperSlide>
          {/* 2 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "Eichi",
                img: eichiKP,
                country: "Korea",
                code: "KR",
                race: "Human",
                body: `Hi, I'm Eichi, I have been creating and posting my artworks on Pixiv since 2019. There are some of my artworks are fanarts, but my passion is to make my imagination into reality, so most of my artworks are original.
                In the past, I rarely add sci-fi twists to my artworks, so the FREEDEN project is a new attempt for me, it takes me a lot of time to add my personal inspirations to the characters of this project, I hope you will love it and enjoy taking them as part of your NFT collections.
                
               `,
                pixiv: `https://www.pixiv.net/users/35286171`,
                twit: `https://twitter.com/skskdi12z`,
              }}
            />
          </SwiperSlide>
          {/* 3 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "Dema",
                img: demaTaiwan,
                country: "Taiwan",
                code: "TW",
                race: "Kitten",
                body: `
                Hello, I'm Dema, 
                I'm an illustrator who loves to draw beautiful things.
                Below are my social media accounts:
                If you like my artwork, please follow my accounts so you can see my updates.
               `,
                pixiv: `https://www.pixiv.net/users/7054584`,
                twit: `https://twitter.com/hmw59750476`,
              }}
            />
          </SwiperSlide>
          {/*  4 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "Nagi",
                img: nagiImg,
                country: "Japan",
                code: "JP",
                race: "Draconic",
                body: `
                Hello~ Hello, everyone! My name is Nagi. In addition to being an office worker after graduating from university, I try my best to create artwork when I have free time. Most of my paintings are watercolor-style works. I hope you like my creations.
             
               `,
                pixiv: `https://www.pixiv.net/users/8095212`,
                twit: `https://twitter.com/shirona_775`,
              }}
            />
          </SwiperSlide>
          {/* 5 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "Eva",
                img: evaUkraine,
                country: "Ukraine",
                code: "UA",
                race: "Fairy",
                body: `
                Hi there, my name is Eva, and I am a digital artist from Ukraine. I create artworks, mostly girl portraits, in anime style. I implemented this style because it allows me to deliver emotions better through still 2D images. I am especially passionate and inspired by older titles of anime and their mysterious nostalgic vibe.
For this project I took inspiration from the title ""Chobits"". The heroine in that show appears ethereal, gentle and otherwordly, just like fairy. My goal in mind is to create charming characters with similar mood and make them closer to reality, using my digital art skills, which is my magical power of choice!

             
               `,
                insta: "https://www.instagram.com/evang3llium/",
                twit: `https://twitter.com/evangellium`,
              }}
            />
          </SwiperSlide>
          {/* 6 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "倉科ゆづき",
                img: 倉科,
                country: "HongKong",
                code: "HKG",
                race: "Foxy",
                body: `
                Hello everyone, my name is Kurashina Yuzuki. I am a professional illustrator in the game industry. I like to draw cute foxes and beautiful girls.
                In this project, I want to present the beauty of the fox girl. If you like my artwork, you can follow me and watch my latest artworks.
                `,
                pixiv: `https://www.pixiv.net/users/3398728`,
                twit: `https://twitter.com/KurashinaYuduki`,
              }}
            />
          </SwiperSlide>
          {/* 7 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "zeronext",
                img: zeronextTaiwan,
                country: "Taiwan",
                code: "TW",
                race: "Mobile Suit",
                body: `
                An Illustrator who leaves the game industry.
                Likes to draw mysterious young girls and Mobile suits. If youc also love Mecha, welcome to follow my social account.
                
              
                `,
                pixiv: `https://www.pixiv.net/users/3449131`,
                twit: `https://twitter.com/SonZaiKanNai`,
              }}
            />
          </SwiperSlide>
          {/* 8 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "AiRool",
                img: airroolChina,
                country: "China",
                code: "CN",
                race: "Demon",
                body: `
                I am a new illustrator who just entered the game industry with a bachelor's degree in architecture, I like to draw in anime style because of its bright colouring style. 
                Once learning and studying from members of the Genshin art department.
                `,
                pixiv: `https://www.pixiv.net/users/11173060`,
                twit: `https://twitter.com/C63025522`,
              }}
            />
          </SwiperSlide>
          {/* 9 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "Avetetsuya StudiosP",
                country: "Indonesia",
                code: "ID",
                race: "Oni",
                img: artistCard2,
                body: `
                Avetetsuya Studio was founded in 2020,located in Indonesia. We focuses on the production of 2D and simple 3D artwork in Asian styles. We provide high-quality digital art design, development and production services with professional workmanship.
                `,
                twit: `https://twitter.com/TetsunyanS`,
              }}
            />
          </SwiperSlide>
          {/* 9 */}
          <SwiperSlide>
            <ArtistCard
              data={{
                name: "四葉",
                country: "Japan",
                code: "JP",
                race: "Mermaid",
                img: 四葉,
                body: `
                After working as an illustrator in a game company, 
                he becomes a freelance illustrator.
                Since then, he has produced illustrations in the fields of mobile games, PC games, and books.
                `,
                twit: ``,
              }}
            />
          </SwiperSlide>
        </Swiper>
      </Fade>
      <div
        className="custom_arrows_artist artist_slider_arrows right_cs_arrow"
        ref={nextRef}
      >
        <img src={arrowRight} alt="" />
      </div>
      <div
        className="custom_arrows_artist artist_slider_arrows left_cs_arrow"
        ref={prevRef}
      >
        <img src={arrowLeft} alt="" />
      </div>
    </div>
  );
};

// SwiperCore.use([Autoplay, Pagination, Navigation, EffectCoverflow]);

export const Gallery = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [swiper, setSwiper] = useState();
  const prevRef = useRef();
  const nextRef = useRef();

  useEffect(() => {
    if (swiper) {
      swiper.params.navigation.prevEl = prevRef.current;
      swiper.params.navigation.nextEl = nextRef.current;
      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, [swiper]);

  let galleryData = [
    { img: eichi_human, race: "Human" },
    { img: none_img, race: "Draconic Girl" },
    { img: none_img, race: "Mermaid" },
    { img: none_img, race: "Mobile Suit" },
    { img: angel_neko, race: "Angel" },
    { img: kitten_dema, race: "Kitten " },
    { img: demon_airool, race: "Demon" },
    { img: fairy_eva, race: "Fairy" },
    { img: none_img, race: "Oni" },
    { img: none_img, race: "Foxy" },
  ];

  return (
    <div className="py-5 bg-black">
      <div className="roundSlider  position-relative">
        <div className="roundSlider_container overflow-hidden">
          <div className="raseImg mb-5">
            <span>{galleryData[activeSlide]?.race}</span>
          </div>
          <div className="position-relative">
            <div className="gallery_swiper_main">
              <Swiper
                modules={[Navigation, EffectCoverflow]}
                slidesPerView={3}
                spaceBetween={100}
                effect={"coverflow"}
                centeredSlides
                onSlideChange={(d) => setActiveSlide(d.realIndex)}
                coverflowEffect={{
                  rotate: 0,
                  // stretch: 10,
                  // depth: 55,
                  stretch: 80,
                  depth: 200,
                  modifier: 5,
                  // initialSlide: 3,
                  slideShadows: false,
                }}
                // navigation={true}
                navigation={{
                  prevEl: prevRef?.current,
                  nextEl: nextRef?.current,
                }}
                loop={true}
                // initialSlide={3}
                breakpoints={{
                  // 270: {
                  //   coverflowEffect: false,
                  //   slidesPerView: 2,
                  //   spaceBetween: 15,
                  // },
                  280: {
                    // coverflowEffect: false,
                    slidesPerView: 2,
                    spaceBetween: 50,
                    coverflowEffect: {
                      rotate: 0,
                      stretch: 48,
                      depth: 160,
                      modifier: 1,
                      // initialSlide: 3,
                      slideShadows: false,
                    },
                  },
                  780: {
                    coverflowEffect: {
                      rotate: 0,
                      // stretch: 10,
                      // depth: 55,
                      stretch: 44,
                      depth: 145,
                      modifier: 2,
                      // initialSlide: 3,
                      slideShadows: false,
                    },
                  },
                  1700: {
                    coverflowEffect: {
                      rotate: 0,
                      // stretch: 10,
                      // depth: 55,
                      stretch: 44,
                      depth: 175,
                      modifier: 2,
                      // initialSlide: 3,
                      slideShadows: false,
                    },
                  },
                }}
                onSwiper={setSwiper}
                className="mb-5 overflow-visible gallery_coverflow_slider"
              >
                {galleryData?.map((v, i) => (
                  <SwiperSlide key={i}>
                    <div className="galley_slider_img_wrapper">
                      <img className="" src={v.img} alt="" />
                    </div>
                  </SwiperSlide>
                ))}
                {/*               
              <SwiperSlide>
                <img className="galley_slider_img" src={art_2} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img className="galley_slider_img" src={art_3} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img className="galley_slider_img" src={art_4} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img className="galley_slider_img" src={art_5} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img className="galley_slider_img" src={art_6} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img className="galley_slider_img" src={art_7} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img className="galley_slider_img" src={art_8} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img className="galley_slider_img" src={art_9} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img className="galley_slider_img" src={art_10} alt="" />
              </SwiperSlide> */}
              </Swiper>
            </div>
            {/*  */}
            <div className="custom_arrows_artist right_cs_arrow" ref={nextRef}>
              <img src={arrowRight} alt="" />
            </div>
            <div className="custom_arrows_artist left_cs_arrow" ref={prevRef}>
              <img src={arrowLeft} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ArtistCard = ({ data }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="artist_card_box">
      <img
        className="img-card m-0 p-0 artist_card_img"
        src={data?.img}
        alt=""
      />
      <div className="artist-card-text">
        {/* <p>{data?.name} {" " } {data?.code ? <Flag height="16px" code={ data?.code }  fallback={ data?.country}/> : `(${data?.country})`}</p> */}
        <p>
          {data?.name}{" "}
          {data?.code ? (
            <Flag height="16px" code={data?.code} fallback={data?.country} />
          ) : (
            ""
          )}
        </p>
        {/* <p>{data?.name} {" " } {data?.code ? <Flag height="16px" code={ data?.code }  fallback={""}/> : null}</p> */}
        <p>{`(${data?.race})`}</p>
        <div
          className={
            open
              ? "artist-dropdown-content artist-dropdown-content-show"
              : "artist-dropdown-content"
          }
        >
          <div className="artist-content-img">
            <img src={ArtistContentImgT} alt="" />
          </div>
          <p className="px-2">{data?.body}</p>
          <div className="artist-content-img">
            <img src={ArtistContentImgB} alt="" />
          </div>
          <div className="artist-socials">
            {data?.fb ? (
              <a href={data?.fb} target="blank">
                <i className="fa-brands fa-facebook-f"></i>
              </a>
            ) : null}
            {data?.insta ? (
              <a href={data?.insta} target="blank">
                <i className="fa-brands fa-instagram"></i>
              </a>
            ) : null}
            {data?.pixiv ? (
              <a href={data?.pixiv} target="blank">
                {/* <i className="fa-solid fa-p"></i> */}
                <img className="pixivIcon" src={pixivIcon} alt="" />
              </a>
            ) : null}
            {data?.twit ? (
              <a href={data?.twit} target="blank">
                <i className="fa-brands fa-twitter"></i>
              </a>
            ) : null}
          </div>
        </div>
        <div
          className={open ? "img-arr img-arr-active" : "img-arr"}
          onClick={() => setOpen(!open)}
        >
          <img src={ArrowDown} alt="arrow" />
        </div>
      </div>
    </div>
  );
};

export default Artists;
