import React from 'react';
import './Footer.css'

const Footer = () => {
    return (
        <div className='footer'>
           <button onClick={()=> window.scrollTo({top: 0, left: 0, behavior: 'smooth'})} className='scrollTop'>↟</button>
           <p className='footer-header'>Social Media</p>
           <div className='footer-soc-icon'>
              {/* <a href="https://www.facebook.com/freeden.io" target="_blank" rel="noopener noreferrer"> <i class="fa-brands fa-facebook-f"></i> </a> */}
              <a href="https://twitter.com/freedenio" target="_blank" rel="noopener noreferrer"> <i class="fa-brands fa-twitter"></i></a>
              <a href="https://www.instagram.com/freeden.io/" target="_blank" rel="noopener noreferrer"> <i class="fa-brands fa-instagram"></i></a>
              <a href="https://discord.com/invite/rnYW6fu3zv" target="_blank" rel="noopener noreferrer"> <i class="fa-brands fa-discord"></i></a>
           </div>
           <p className='footer-text'>© {new Date().getFullYear()} FREEDEN</p>
        </div>
    );
};

export default Footer;