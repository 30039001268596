import React from "react";
import deskTwoBanner from "../../../imagee/secondBanner.jpg";
import ourMissionLogo from "../../asset/metaEvents/eventsLogo.png";
import TopBannerLogo from "../../../imagee/deskTwoLogo.jpg";
import MarginOne from "../../../imagee/missionMarginOne.jpg";
import MarginTwo from "../../../imagee/missionMargintwo.jpg";
import sketchNew from "../../../imagee/artist-drawing-anime-w.jpg";
import skatch from "../../../imagee/skatch.jpg";
import "./Banner.css";
import Card from "../Card/Card";
import { Container } from "react-bootstrap";
import ContactUs from "../ContactUs/ContactUs";
import Footer from "../../Shared/Footer/Footer";
import Navigation2 from "../../Shared/Navigation2/Navigation2";

const Banner = () => {
  const [showButton, SetshowButton] = React.useState(false);
  return (
    <div className="desktop2-beg" id="home">
      <div className="banner recuiter_bg">
      <Navigation2 mintPage/>
        <img className="artistRecruit-banner d-md-block d-none" src={deskTwoBanner} alt="" />
        <img
          className="artistRecruite-banner-logo img-fluid"
          src={TopBannerLogo}
          alt=""
        />
      </div>

      <div className="ourmission-style bg-white">
        <div className="our-mission-logo text-center pt-5">
          <img src={ourMissionLogo} alt="" />
        </div>
        <div>
          <p className="mission-text pb-2">OUR MISSION</p>
          <img className="img-fluid" src={MarginOne} alt="" />
          <p className="mission-description px-4">
            As senior animation and game lovers, we have known many artists in
            the ACG industry for a long time, and the most common memory we hear
            from them is how to progress from part-time artists to full-time
            artists. They were full of enthusiasm and ambition initially.
            However, they didn't expect that there would only be continuous
            obstacles and endless loneliness, like a lonely traveler looking for
            an oasis in a mirage in the desert. 
            「Is there {showButton && `ny future in doing
            this?」 「What's this? Are you drawing real art?」 「Will you not
            starve to death by painting this every day?」 ... These are the most
            frequently asked questions, casual but heartbroken every time. 「I
            was thinking every day, should I keep going?」 An artist friend said
            with a smile, but the sadness behind it is undoubted. Some of these
            artists are lucky enough to accumulate more artwork and become more
            famous. Slowly they have found a way and can continue to fulfill
            their dreams every day. unfortunately, many talented artists cannot
            resist the pressure of relatives and friends and the cruelty of
            life, so they have to slowly give up their dreams.`}
          </p>
          <div className="my-4 text-center">
            {showButton ? null : (
              <button
                type="button"
                className="learn-more mx-auto bg-pr text-dark"
                onClick={() => SetshowButton(true)}
              >
                Learn More <i class="fa fa-chevron-down" aria-hidden="true"></i>
              </button>
            )}
          </div>
          {showButton ? (
            <p className="mission-description px-4">
              At present, the art world does not know much about NFT and the
              metaverse, but we believe that through the decentralized
              technology of NFT, it is possible to have "true ownership" of
              digital assets in the virtual world. At the beginning of 2021 last
              year, more well-known Brands and artists began to issue their own
              NFTs, and more people gradually accepted this novel concept. An
              NFT is not just a picture; it represents unique characteristics
              and the charm of permanent preservation. We believe this is a
              perfect opportunity for ACG artists. We hope that by assisting
              more ACG artists, they can rise their reputation through NFT. As
              well as earn a living through selling their digital artworks, we
              are willing to provide our utmost assistance.
            </p>
          ) : null}
          <img className="img-fluid pb-5" src={MarginTwo} alt="" />
          <Container>
            <img className="skatch" src={sketchNew} alt="" />
          </Container>
        </div>
      </div>
      <div className="back-style">
        <Container className=" pb-5">
          <div className="container-color text-center">
            <div className="our-mission-logo pt-5">
              <img src={ourMissionLogo} alt="" />
            </div>
            <div>
              <span className="acg-artist">ACG Artist Support Community</span>{" "}
              <br />
              <br />
              <p className="acg-artist-description px-4">
                The crux of our mission and value is to provide support to ACG
                artists, and we want to do that for a number of <div className="d-md-block d-inline"> reasons.
                A worldwide pandemic made it harder for creatives to sell their
                artwork in the real world due to heavy </div><div className="d-md-block d-inline"> restrictions. That
                is why we wanted to bring the show to web 3.0, and provide
                utility to an often underappreciated</div> <div className="d-md-block d-inline"> group; ACG artists.
                We’ll give them the tools to create their own NFT artwork, and
                help them to progress in their field.</div>
              </p>
            </div>
          </div>
        </Container>
        <div className="card-area-style">
          <Card></Card>
        </div>
      </div>
      <ContactUs />
      <Footer />
    </div>
  );
};

export default Banner;
