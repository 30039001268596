import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Autoplay, Pagination } from "swiper";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Fade } from "react-reveal";
import eventLogo from "../../asset/metaEvents/eventsLogo.png";
import evennt1 from "../../asset/metaEvents/event1.png";
import evennt2 from "../../asset/metaEvents/event2.png";
import evennt3 from "../../asset/metaEvents/event3.png";
import evennt4 from "../../asset/metaEvents/event4.png";
import evennt5 from "../../asset/metaEvents/event5.png";
import evennt6 from "../../asset/metaEvents/event6.png";
import topLine from "../../asset/metaEvents/topLine.png";
import bottomLine from "../../asset/metaEvents/bottomLine.png";
import "./MetaEvents.css";

const MetaEvents = () => {
  return (
    <div className="metaEvents" id="metaevent">
      <div>
        <Fade bottom>
          <img className="mt-5" src={eventLogo} alt="" />
          <p className="metaHeading">Metaverse ACG Events</p>
          <p className="meta-description mb-5">
            Metaverse Anime-Cons / Anime Concerts / Vtuber meetups/Exclusive
            online auctions <br />
            We will hold more metaverse events through community voting in the
            future.
          </p>
        </Fade>
      </div>
      
      <Container>
        <img className="img-fluid mb-5" src={topLine} alt="" />
        <div className="desktop-metaevents">
          <div className="text-start">
            <Row xs={1} md={2} className="g-5 mx-0 px-0">
              <Col>
                <Fade bottom>
                  <Card className="eventCard">
                    <Card.Body>
                      <Card.Title className="event-title">
                        1. Metaverse Anime-Cons.
                      </Card.Title>
                      <Card.Text className="event-text">
                        We will invite many artists to the exhibition to
                        demonstrate their unique ACG artworks, holding meetups and
                        interviews, taking our members to a rare and unique world.
                      </Card.Text>
                    </Card.Body>
                    <Card.Img variant="top" src={evennt1} />
                  </Card>
                </Fade>
              </Col>

              <Col>
                <Fade bottom>
                  <Card className="eventCard">
                    <Card.Body>
                      <Card.Title className="event-title">
                        2. Anime Concerts
                      </Card.Title>
                      <Card.Text className="event-text">
                        Invite ACG singers and voice actors to participate in our
                        metaverse concert. We dedicate to bringing different
                        points of view on stage so that our members can meet more
                        talents, encourage new artists' relentless effort, and
                        inspire more members to join this industry.
                      </Card.Text>
                    </Card.Body>
                    <Card.Img variant="top" src={evennt2} />
                  </Card>
                </Fade>
              </Col>
              <Col>
                <Fade bottom>
                  <Card className="eventCard">
                    <Card.Body>
                      <Card.Title className="event-title">
                        3. Vtuber Meetups
                      </Card.Title>
                      <Card.Text className="event-text">
                        Host a Vtuber virtual world meeting, allowing fans to
                        interact with their favorite idols in the metaverse and
                        play small games together, and even participate in
                        exclusive private concerts and singing activities
                      </Card.Text>
                    </Card.Body>
                    <Card.Img variant="top" src={evennt3} />
                  </Card>
                </Fade>
              </Col>
              <Col>
                <Fade bottom>
                  <Card className="eventCard">
                    <Card.Body>
                      <Card.Title className="event-title">
                        4. Exclusive online auctions
                      </Card.Title>
                      <Card.Text className="event-text">
                        We will cooperate with ACG artists worldwide to create
                        exclusive anime artworks for FREEDEN members. The artworks
                        will be sold in the form of NFT, and auctions will be held
                        in our Discord or Metaverse to ensure that members can
                        collect their favorite work. Everyone who want to join the
                        bid must complete the identity check and connect their
                        blockchain wallet to ensure transaction safety and prevent
                        auctions from trolls and malicious bidders
                      </Card.Text>
                    </Card.Body>
                    <Card.Img variant="top" src={evennt4} />
                  </Card>
                </Fade>
              </Col>
            </Row>
          </div>
        </div>
        <div className="mobile-metaevents">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            freeMode={true}
            pagination={{
              clickable: true,
            }}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            modules={[FreeMode, Autoplay, Pagination]}
            className="mySwiper"
            >
              <SwiperSlide>
                <Fade bottom>
                  <Card className="eventCard">
                    <Card.Body>
                      <Card.Title className="event-title">
                        1. Metaverse Anime-Cons.
                      </Card.Title>
                      <Card.Text className="event-text">
                        We will invite many artists to the exhibition to
                        demonstrate their unique ACG artworks, holding meetups and
                        interviews, taking our members to a rare and unique world.
                      </Card.Text>
                    </Card.Body>
                    <Card.Img variant="top" src={evennt1} />
                  </Card>
                </Fade>
              </SwiperSlide>
              <SwiperSlide>
                <Fade bottom>
                  <Card className="eventCard">
                    <Card.Body>
                      <Card.Title className="event-title">
                        2. Anime Concerts
                      </Card.Title>
                      <Card.Text className="event-text">
                        Invite ACG singers and voice actors to participate in our
                        metaverse concert. We dedicate to bringing different
                        points of view on stage so that our members can meet more
                        talents, encourage new artists' relentless effort, and
                        inspire more members to join this industry.
                      </Card.Text>
                    </Card.Body>
                    <Card.Img variant="top" src={evennt2} />
                  </Card>
                </Fade>
              </SwiperSlide>
              <SwiperSlide>
                <Fade bottom>
                  <Card className="eventCard">
                    <Card.Body>
                      <Card.Title className="event-title">
                        3. Vtuber Meetups
                      </Card.Title>
                      <Card.Text className="event-text">
                        Host a Vtuber virtual world meeting, allowing fans to
                        interact with their favorite idols in the metaverse and
                        play small games together, and even participate in
                        exclusive private concerts and singing activities
                      </Card.Text>
                    </Card.Body>
                    <Card.Img variant="top" src={evennt3} />
                  </Card>
                </Fade>
              </SwiperSlide>
              <SwiperSlide>
                <Fade bottom>
                  <Card className="eventCard">
                    <Card.Body>
                      <Card.Title className="event-title">
                        4. Exclusive online auctions
                      </Card.Title>
                      <Card.Text className="event-text">
                        We will cooperate with ACG artists worldwide to create
                        exclusive anime artworks for FREEDEN members. The artworks
                        will be sold in the form of NFT, and auctions will be held
                        in our Discord or Metaverse to ensure that members can
                        collect their favorite work. Everyone who want to join the
                        bid must complete the identity check and connect their
                        blockchain wallet to ensure transaction safety and prevent
                        auctions from trolls and malicious bidders
                      </Card.Text>
                    </Card.Body>
                    <Card.Img variant="top" src={evennt4} />
                  </Card>
                </Fade>
              </SwiperSlide>
            </Swiper>
        </div>
        <img className="img-fluid mt-5 mb-5 pb-5" src={bottomLine} alt="" />
        {/* <a href="#exibition"><button className='scrollTop mb-5'>↡</button></a> */}
        <div className="px-2">
          <Fade bottom>
            <img src={eventLogo} alt="" />
          </Fade>
          <Fade bottom>
            <p className="metaHeading" id="exibition">
              Online Personal Collection Exhibition
            </p>
          </Fade>
          <Fade bottom>
            <p className="meta-description ms-md-5 me-md-5 ps-md-5 pe-md-5">
              We understand that fans also want to have the opportunity to
              showcase their collections and tastes and, at the same time to
              express their support to the artists they admire and share with
              fellow fans. With the space we own in the Metaverse, Members have
              the opportunity to host a showcase of their collections, allowing
              those who have collected multiple NFTs to exhibit their
              collections and arrange their own exhibition space. They can
              invite friends, or we can help them publicize where he can see
              their collections (as shown below)"
            </p>
          </Fade>
        </div>
        <div></div>

        <div className="mt-5 pb-5">
          <Fade bottom>
            <div className="exibition-imgs-bg"> 
                <Row xs={1} md={2} className="g-5 mx-0 px-0">
                <Col>
                    <Card className="eventCard">
                    <Card.Img variant="top" src={evennt5} />
                    </Card>
                </Col>
                <Col>
                    <Card className="eventCard">
                    <Card.Img variant="top" src={evennt6} />
                    </Card>
                </Col>
                </Row>
            </div>
          </Fade>
        </div>
      </Container>
      {/* <a href="#giveaway"><button className='scrollTop mb-5'>↡</button></a> */}
    </div>
  );
};

export default MetaEvents;
