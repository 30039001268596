import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Autoplay, Pagination } from "swiper";
import { Fade } from "react-reveal";
import utlogo from "../../asset/artist/utilitiesLogo.png";

import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/autoplay";
import "./Utilities.css";
const Utilities = () => {
  return (
    <div id="utilities" className="utilities">
      <div>
        <Fade bottom>
          <img src={utlogo} alt="" />
        </Fade>
        <p className="utilities-heading">U T I L I T Y</p>
      </div>
      <div className="utilities-points desktop-utility pb-md-5">
        <ul>
          <Fade bottom>
            <li>
              <p>
                <span className="point-heading">
                  ✦ Stageverse Exclusive Metaverse Club:
                </span>
                Our NFT holders can enter the club without cost; With real hired
                receptionists and weekly hold events, members can socialize and
                have fun freely in the club.
              </p>
            </li>
          </Fade>
          <Fade bottom>
            <li>
              <p>
                <span className="point-heading">
                  ✦ Metaverse Exhibitions and events:
                </span>
                we will hold various large-scale events in the Metaverse,
                including exhibitions, vtuber meetups, and anime concerts....the
                possibilities are unlimited.
              </p>
            </li>
          </Fade>
          <Fade bottom>
            <li>
              <p>
                <span className="point-heading">
                  ✦ Weekly Sweepstakes &amp; Giveaway:
                </span>
                Holders can mint our 3D NFTs for free and join our weekly
                sweepstakes with various prizes. More different prizes can be
                added to the lottery pool through community proposals and
                voting.
              </p>
            </li>
          </Fade>
          <Fade bottom>
            <li>
              <p>
                <span className="point-heading">
                  ✦ Offline privilege and events:
                </span>
                Offline practical utilities specially provided for ACG
                enthusiasts. Global Anime Con Support, Line Sitting Service,
                Offline Convention Party.
              </p>
            </li>
          </Fade>
          <Fade bottom>
            <li>
              <p>
                <span className="point-heading">✦ On-chain Voting: </span> We
                are a highly community-driven organization. Through our
                specially developed platform, NFT holders can vote on the
                community's proposals to determine the future direction of the
                community.
              </p>
            </li>
          </Fade>
          <Fade bottom>
            <li>
              <p>
                <span className="point-heading">✦ Special Bonuses: </span>
                Staking FREEDEN NFTs give holders special bonuses， Holders who
                purchase more than 1 NFT will enjoy exclusive benefits.
              </p>
            </li>
          </Fade>
        </ul>
      </div>
      <div className="utilities-points pb-md-5">
        <div className="mobile-utility-swiper">
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            // freeMode={true}
            pagination={{
              clickable: true,
            }}
            // autoplay={{ delay: 3000, disableOnInteraction: false }}
            modules={[ Autoplay, Pagination]}
            className="mySwiper"
            >
            <SwiperSlide>
            <ul className="m-0 p-0">
              <li>
                <span className="point-heading">
                  ✦ Stageverse Exclusive Metaverse Club:
                </span>
                <p>
                  <br />
                  Our NFT holders can enter the club without cost; With real hired
                  receptionists and weekly hold events, members can socialize and
                  have fun freely in the club.
                </p>
              </li>
            </ul>
            </SwiperSlide>
            <SwiperSlide>
            <ul className="m-0 p-0">
              <li>
                <span className="point-heading">
                  ✦ Metaverse Exhibitions and events:
                </span>
              <p>
                <br />
                we will hold various large-scale events in the Metaverse,
                including exhibitions, vtuber meetups, and anime concerts....the
                possibilities are unlimited.
              </p>
              </li>
            </ul>
            </SwiperSlide>
            <SwiperSlide>
            <ul className="m-0 p-0">
              <li>
                <span className="point-heading">
                    ✦ Weekly Sweepstakes &amp; Giveaway:
                </span>
                <p>
                  <br />
                  Holders can mint our 3D NFTs for free and join our weekly
                  sweepstakes with various prizes. More different prizes can be
                  added to the lottery pool through community proposals and
                  voting.
                </p>
              </li>
            </ul>
            </SwiperSlide>
            <SwiperSlide>
            <ul className="m-0 p-0">
              <li>
                <span className="point-heading">
                    ✦ Offline privilege and events:
                  </span>
                <p>
                  <br />
                  Offline practical utilities specially provided for ACG
                  enthusiasts. Global Anime Con Support, Line Sitting Service,
                  Offline Convention Party.
                </p>
              </li>
            </ul>
            </SwiperSlide>
            <SwiperSlide>
            <ul className="m-0 p-0">
            <li>
              <span className="point-heading">✦ On-chain Voting: </span>
                <p>
                  <br />
                  We are a highly community-driven organization. Through our
                  specially developed platform, NFT holders can vote on the
                  community's proposals to determine the future direction of the
                  community.
                </p>
              </li>
            </ul>
            </SwiperSlide>
            <SwiperSlide>
            <ul className="m-0 p-0">
              <li>
                  <span className="point-heading">✦ Special Bonuses: </span>
                <p>
                  <br />
                  Staking FREEDEN NFTs give holders special bonuses， Holders who
                  purchase more than 1 NFT will enjoy exclusive benefits.
                </p>
              </li>
            </ul>
            </SwiperSlide>          
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Utilities;
