import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Autoplay, Pagination } from "swiper";
import { Col, Container, Row } from "react-bootstrap";
import logo from "../../asset/ourTeam/teamLogo.png";
import topLine from "../../asset/metaEvents/topLine.png";
import bottomLine from "../../asset/metaEvents/bottomLine.png";
import img1 from "../../asset/ourTeam/Rayj.png";
import img2 from "../../asset/ourTeam/Taichi.png";
import img3 from "../../asset/ourTeam/TojunnS.png";
import img4 from "../../asset/ourTeam/Kenji.png";
import img5 from "../../asset/ourTeam/ShiShi.png";
import img6 from "../../asset/ourTeam/Shaka.png";
import "./OurTeam.css";
import { Fade } from "react-reveal";

const OurTeam = () => {
  return (
    <div className="ourteam-section" id="ourTeam">
      <Container>
        <Fade bottom>
          <div>
            <img className="img-fluid mt-5 pt-3" src={logo} alt="" />
          </div>
        </Fade>
        <div className="ourteam mt-4 pb-5">
          <img src={topLine} alt="" />
          {/*  desktop card style for team*/}
          <div className="desktop-team-members">
            <Row className="card-wraper g-4">
              <Col className="col-md-6">
                <Fade bottom>
                  <div className="team-card mx-5 my-3">
                    <img className="img-fluid card-img" src={img1} alt="" />
                    <p className="team-title">Ray J </p>
                    <p>(Founder)</p>
                    <p className="team-des">
                      Ray has years of experience working in large technology
                      enterprises as a department leader and director, familiar
                      with cloud and internet technology and strategic thinking.
                      He is also a Blockchain enthusiast and NFT Investor,
                      always craving more knowledge. His recent favorite anime
                      is Jujutsu Kaisen and My Hero Academia.
                    </p>
                  </div>
                </Fade>
              </Col>
              <Col className="col-md-6">
                <Fade bottom>
                  <div className="team-card mx-5 my-3">
                    <img className="img-fluid card-img" src={img2} alt="" />
                    <p className="team-title">TaiChi </p>
                    <p>(Strategic Manager)</p>
                    <p className="team-des">
                      Taichi is a trilingual Japanese Anime Brand Ambassador who
                      speaks Japanese, English, and Mandarin. He has a deep
                      understanding of unifying different cultures. This helped
                      him build a resourceful international KOL network. He
                      dedicates to leading the FREEDEN team to create a platform
                      that can unite anime artists and lovers.
                    </p>
                  </div>
                </Fade>
              </Col>

              <Col className="col-md-6 ">
                <Fade bottom>
                  <div className="team-card mx-5 my-3">
                    <img className="img-fluid card-img" src={img3} alt="" />
                    <p className="team-title">Tojun S</p>
                    <p>(Marketing Manager)</p>
                    <p className="team-des">
                      Social Listening Analysis &amp; WOM marketing strategy
                      Specialist, long-term investor, and observer in Gamefi &amp;
                      NFT love to play ARPG and open-world games, blood boiling
                      anime fans. Currently, offering learned specialty for
                      popularizing NFT and metaverse.
                    </p>
                  </div>
                </Fade>
              </Col>
              <Col className="col-md-6 ">
                <Fade bottom>
                  <div className="team-card mx-5 my-3">
                    <img className="img-fluid card-img" src={img4} alt="" />
                    <p className="team-title">Kenji W</p>
                    <p>(Project Advisor)</p>
                    <p className="pt-4">
                      Kenji is an experienced crypto trader; he has also led a
                      professional crypto research team for years. He is also
                      crazy about open-world survival and MMORPG games, a huge
                      fan of Attack on titans, Evangelin, Gundam W, and Rurouni
                      Kenshin.
                    </p>
                  </div>
                </Fade>
              </Col>

              <Col className="col-md-6 ">
                <Fade bottom>
                  <div className="team-card mx-5 my-3">
                    <img className="img-fluid card-img" src={img5} alt="" />
                    <p className="team-title">ShiShi</p>
                    <p>(Community Manager)</p>
                    <p className="team-des">
                      He has many years of experience in ACG culture research.
                      And has connections with artists from all over the world.
                      He has his unique views on ACG artworks, also good at
                      building community. His master plan is to create a
                      borderless community to gather ACG lovers from different
                      cultures or countries.
                    </p>
                  </div>
                </Fade>
              </Col>
              <Col className="col-md-6 ">
                <Fade bottom>
                  <div className="team-card mx-5 my-3">
                    <img className="img-fluid card-img" src={img6} alt="" />
                    <p className="team-title">Shaka O</p>
                    <p>(Tech Specilaist)</p>
                    <p className="team-des">
                      Shaka is an anime enthusiast passionate about anime
                      culture and background studying. He is a fully experienced
                      crypto trading specialist based on years of crypto data
                      and technology research. He is also a hard-core gamer who
                      likes to work as a team to overcome challenges.
                    </p>
                  </div>
                </Fade>
              </Col>
            </Row>
          </div>
          {/*  mobile slider for team */}
          <div className="mobile-team-section-swiper">
            <Swiper
            slidesPerView={1}
            spaceBetween={24}
            // freeMode={true}
            pagination={{
              clickable: true,
            }}
            // autoplay={{ delay: 3000, disableOnInteraction: false }}
            modules={[Autoplay, Pagination]}
            className="mySwiper pb-3"
            >
              <SwiperSlide>
                <div className="team-card mx-auto">
                  <div className="team-member-img">
                    <img className="img-fluid card-img" src={img1} alt="" />
                  </div>
                  <p className="team-title">Ray J </p>
                  <p>(Founder)</p>
                  <p className="team-des">
                    Ray has years of experience working in large technology
                    enterprises as a department leader and director, familiar
                    with cloud and internet technology and strategic thinking.
                    He is also a Blockchain enthusiast and NFT Investor,
                    always craving more knowledge. His recent favorite anime
                    is Jujutsu Kaisen and My Hero Academia.
                  </p>
                </div>
              </SwiperSlide>  
              <SwiperSlide>
                <div className="team-card">
                  <div className="team-member-img">
                    <img className="img-fluid card-img" src={img2} alt="" />
                  </div>
                  <p className="team-title">TaiChi </p>
                  <p>(Strategic Manager)</p>
                  <p className="team-des">
                    Taichi is a trilingual Japanese Anime Brand Ambassador who
                    speaks Japanese, English, and Mandarin. He has a deep
                    understanding of unifying different cultures. This helped
                    him build a resourceful international KOL network. He
                    dedicates to leading the FREEDEN team to create a platform
                    that can unite anime artists and lovers.
                  </p>
                </div>
              </SwiperSlide>  
              <SwiperSlide>
                <div className="team-card">
                  <div className="team-member-img">
                    <img className="img-fluid card-img" src={img3} alt="" />
                  </div>
                  <p className="team-title">Tojun S</p>
                    <p>(Marketing Manager)</p>
                    <p className="team-des">
                      Social Listening Analysis &amp; WOM marketing strategy
                      Specialist, long-term investor, and observer in Gamefi &amp;
                      NFT love to play ARPG and open-world games, blood boiling
                      anime fans. Currently, offering learned specialty for
                      popularizing NFT and metaverse.
                    </p>
                </div>
              </SwiperSlide>  
              <SwiperSlide>
                <div className="team-card">
                  <div className="team-member-img">
                    <img className="img-fluid card-img" src={img4} alt="" />
                  </div>
                  <p className="team-title">Kenji W</p>
                    <p>(Project Advisor)</p>
                    <p className="pt-4">
                      Kenji is an experienced crypto trader; he has also led a
                      professional crypto research team for years. He is also
                      crazy about open-world survival and MMORPG games, a huge
                      fan of Attack on titans, Evangelin, Gundam W, and Rurouni
                      Kenshin.
                    </p>
                </div>
              </SwiperSlide>  
              <SwiperSlide>
                <div className="team-card">
                  <div className="team-member-img">
                    <img className="img-fluid card-img" src={img5} alt="" />
                  </div>
                  <p className="team-title">ShiShi</p>
                    <p>(Community Manager)</p>
                    <p className="team-des">
                      He has many years of experience in ACG culture research.
                      And has connections with artists from all over the world.
                      He has his unique views on ACG artworks, also good at
                      building community. His master plan is to create a
                      borderless community to gather ACG lovers from different
                      cultures or countries.
                    </p>
                </div>
              </SwiperSlide>  
              <SwiperSlide>
                <div className="team-card">
                  <div className="team-member-img">
                    <img className="img-fluid card-img" src={img6} alt="" />
                  </div>
                  <p className="team-title">Shaka O</p>
                    <p>(Tech Specilaist)</p>
                    <p className="team-des">
                      Shaka is an anime enthusiast passionate about anime
                      culture and background studying. He is a fully experienced
                      crypto trading specialist based on years of crypto data
                      and technology research. He is also a hard-core gamer who
                      likes to work as a team to overcome challenges.
                    </p>
                </div>
              </SwiperSlide>  
            </Swiper>
          </div>
          <img src={bottomLine} alt="" />
        </div>
      </Container>
      {/* <a href="#roadmap"><button className='scrollTop mt-3' >↡</button></a> */}
    </div>
  );
};

export default OurTeam;
