import {useEffect, useState} from "react";
import { Container } from "react-bootstrap";
import { Fade } from "react-reveal";
import giveWayLogo from "../../asset/giveway/giveWayLogo.png";
import eventLogo from "../../asset/metaEvents/eventsLogo.png";
import event1 from "../../asset/offlineEvents/img1.png";
import event2 from "../../asset/offlineEvents/img2.png";
import event3 from "../../asset/offlineEvents/img3.png";
import event4 from "../../asset/offlineEvents/img4.png";
import event5 from "../../asset/offlineEvents/img5.png";
import event6 from "../../asset/offlineEvents/img6.png";
import event7 from "../../asset/offlineEvents/img7.png";
import "./OfflineEvents.css";

// 
const OfflineEvents = () => {
  const [showButton, SetshowButton] = useState(false);

  // 
  const ToggleText = () => {
    SetshowButton(!showButton);
  };

  
  // const [windowSize, setWindowSize] = useState(null);

  // useEffect(() => {
  //   const handleResize = () => {
  //     let size =window.innerWidth;

  //    if(size > 768){
  //     SetshowButton(true);
  //   }else{
  //     SetshowButton(false);
  //    }
  //     // setWindowSize(window.innerWidth);
  //   };

  //   window.addEventListener("resize", handleResize);
  //   window.addEventListener("load", function () {
  //     handleResize()
  //   });

  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  return (
    <div className="offlineEvents" id="offline">
      <Container>
        <Fade bottom>
          <div>
            <img
              className="mt-5 img-fluid pb-4 pt-5"
              src={giveWayLogo}
              alt=""
            />
            <p className="giveWayHeading text-uppercase">
              Offline privilege and events
            </p>
          </div>
        </Fade>
        <div>
          <Fade bottom>
            <img className="mt-5" src={eventLogo} alt="" />
            <p className="lotery-text ">Global Anime Con Support</p>
          </Fade>
        </div>
        {/* events card div/// */}
        <section className="offline-event-wraper">
          <Fade bottom>
            <div>
              <p className="support-text" style={{ opacity: ".8" }}>
                ACG exhibition is a big event for lovers. Unfortunately, fans
                and artists often choose not to eat or hydrate because of
                inconvenience. We will provide free support to our holders at
                selected ACG exhibitions worldwide. It could be renting an
                exhibition booth or a food truck. It could be renting an
                exhibition booth or a food truck.
              </p>
              <div className="off-event-img">
                <img src={event1} alt="" />
                {showButton && <img className="d-block d-md-none" src={event2} alt="" />}
                 <img className="d-md-block d-none" src={event2} alt="" />
              </div>
              {/* mobile content */}
              {showButton && (
                <div className="d-md-none d-block">
                  <div>
                    <p
                      className={
                        showButton
                          ? "support-text show-text-active"
                          : "support-text show-text"
                      }
                      style={{ opacity: ".8" }}
                    >
                      We will offer complimentary bottled water to everyone, and
                      they can also buy lunchboxes from us if they want. But for
                      FREEDEN NFT holders, all meals are free, and they have the
                      first priority to take lunchbox. We will also provide more
                      choices of food and drinks such as coffee, tea, snacks,
                      etc. There is also an exclusive rest area for FREEDEN NFT
                      holders and an artists meeting rest area served by VIP
                      receptionists. We will make anime lovers feel supported
                      while attending their special ACG events. FreEden will
                      appear in top ACG-related conventions around the world.
                      NFT holders also share the profits of product selling in
                      these conventions
                    </p>
                  </div>
                  <div className="off-event-img">
                    <img src={event3} alt="" />
                    <img src={event4} alt="" />
                  </div>
                  <div>
                    <p className="support-title">Free line sitting service:</p>
                    <p className="support-text" style={{ opacity: ".8" }}>
                      Do you see their exhausted eyes? Congratulations to those
                      who always wait in the line for limited ACG products; we
                      know that carrying all the stuff with hunger and lack of
                      sleep can be quite a suffering. As long as there are
                      limited products need to queue in the selected convention,
                      we will draw five lucky winners and offer them free line
                      sitting service. it can also be resold or given to others
                    </p>
                    <p className="support-title">Offline Convention Party</p>
                    <p className="support-text" style={{ opacity: ".8" }}>
                      In order to give fans the opportunities to interact with
                      their favorite artists, we will hold a party after the
                      exhibition.
                    </p>
                  </div>
                  <div className="off-event-img">
                    <img src={event5} alt="" />
                    <img src={event6} alt="" />
                  </div>
                  <div>
                    <p className="support-text" style={{ opacity: ".8" }}>
                      We will invite artists to join our party to celebrate and
                      have fun with us, strengthen the connection between
                      artists and fans, and provide special events and prizes at
                      the party.
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <img className="w-100" src={event7} alt="" />
                  </div>
                  <div>
                    <p className="support-title">All costs are on FREEDEN.</p>
                  </div>
                </div>
              )}
              {/* desktop content */}
                <div className="d-md-block d-none">
                  <div>
                    <p
                      className={
                        showButton
                          ? "support-text show-text-active"
                          : "support-text show-text"
                      }
                      style={{ opacity: ".8" }}
                    >
                      We will offer complimentary bottled water to everyone, and
                      they can also buy lunchboxes from us if they want. But for
                      FREEDEN NFT holders, all meals are free, and they have the
                      first priority to take lunchbox. We will also provide more
                      choices of food and drinks such as coffee, tea, snacks,
                      etc. There is also an exclusive rest area for FREEDEN NFT
                      holders and an artists meeting rest area served by VIP
                      receptionists. We will make anime lovers feel supported
                      while attending their special ACG events. FreEden will
                      appear in top ACG-related conventions around the world.
                      NFT holders also share the profits of product selling in
                      these conventions
                    </p>
                  </div>
                  <div className="off-event-img">
                    <img src={event3} alt="" />
                    <img src={event4} alt="" />
                  </div>
                  <div>
                    <p className="support-title">Free line sitting service:</p>
                    <p className="support-text" style={{ opacity: ".8" }}>
                      Do you see their exhausted eyes? Congratulations to those
                      who always wait in the line for limited ACG products; we
                      know that carrying all the stuff with hunger and lack of
                      sleep can be quite a suffering. As long as there are
                      limited products need to queue in the selected convention,
                      we will draw five lucky winners and offer them free line
                      sitting service. it can also be resold or given to others
                    </p>
                    <p className="support-title">Offline Convention Party</p>
                    <p className="support-text" style={{ opacity: ".8" }}>
                      In order to give fans the opportunities to interact with
                      their favorite artists, we will hold a party after the
                      exhibition.
                    </p>
                  </div>
                  <div className="off-event-img">
                    <img src={event5} alt="" />
                    <img src={event6} alt="" />
                  </div>
                  <div>
                    <p className="support-text" style={{ opacity: ".8" }}>
                      We will invite artists to join our party to celebrate and
                      have fun with us, strengthen the connection between
                      artists and fans, and provide special events and prizes at
                      the party.
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <img className="w-100" src={event7} alt="" />
                  </div>
                  <div>
                    <p className="support-title">All costs are on FREEDEN.</p>
                  </div>
                </div>
                {/*  */}
              <div className="mt-2 d-md-none d-block text-center">
                {showButton ? null : (
                  <button
                    type="button"
                    className="learn-more mx-auto"
                    onClick={ToggleText}
                  >
                  Learn More { " " }
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                   {/* {!showButton ? "Learn More " : "Minimize "} {" "} */}
                   {/* {!showButton ?
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                    :
                    <i class="fa fa-chevron-up" aria-hidden="true"></i>} */}
                  </button>
                 )} 
              </div>
            </div>
          </Fade>
        </section>
        {/* <a href="#voting"><button className='scrollTop mt-3' >↡</button></a> */}
      </Container>
    </div>
  );
};

export default OfflineEvents;
