import React from 'react';
import { Container } from 'react-bootstrap';
import roadmapLogo from '../../asset/roadmap/roadmapLogo.png'
import img1 from '../../asset/roadmap/parse1.png'
import img2 from '../../asset/roadmap/parse2.png'
import img3 from '../../asset/roadmap/parse3.png'
import img4 from '../../asset/roadmap/parse4.png'
import img5 from '../../asset/roadmap/parse5.png'
import img6 from '../../asset/roadmap/parse6.png'
import mob1 from '../../asset/roadmap/mobile1.png'
import mob2 from '../../asset/roadmap/mobile2.png'
import mob3 from '../../asset/roadmap/mobile3.png'
import mob4 from '../../asset/roadmap/mobile4.png'
import mob5 from '../../asset/roadmap/mobile5.png'
import mob6 from '../../asset/roadmap/mobile6.png'
import './Roadmap.css'
import { Fade } from 'react-reveal';
const Roadmap = () => {
    return (
        <div className='roadmap' id="roadmap">
            <Container>
                <div className="roadmap-wraper">
                    <Fade bottom>
                    <div className='pt-5'>
                    <img className='img-fluid' src={roadmapLogo} alt="" />
                    </div>
                    </Fade>
                   
                   {/* parse/// */}
                    <Fade bottom>
                    <div className='parse-step mt-5'>
                          <img className='img-fluid parse-img' src={img1} alt="" />
                          <img className='img-fluid mob-img' src={mob1} alt="" />
                          <div className='parse-des'>
                              <ul>
                                    <li> Establish social media presence</li>
                                    <li> Website launch</li>
                                    <li> Recruit Moderators</li>
                                    <li> launching Community events</li>
                                    <li> Build FREEDEN NFT smart contract</li>
                              </ul>
                          </div>   
                    </div>
                    </Fade>
                    <Fade bottom>
                    <div className='parse-step mt-5'>
                          <img className='img-fluid parse-img' src={img2} alt="" />
                          <img className='img-fluid mob-img' src={mob2} alt="" />
                          <div className='parse-des'>
                              <ul>
                                <li> NFT Pre-sale</li>
                                <li> NFT Public-sale</li>
                                <li> More giveaway events</li>
                                <li> Offline activity in Taipei International Comics & Animation Festival 7/28~8/1</li>
                                <li> Offline activity in Tokyo Comike 8/13~8/14</li>
                                <li> Launch artist scholarship program</li>             
                              </ul>
                          </div>   
                    </div>
                    </Fade>
                    <Fade bottom>
                    <div className='parse-step mt-5'>
                          <img className='img-fluid parse-img' src={img3} alt="" />
                          <img className='img-fluid mob-img' src={mob3} alt="" />
                          <div className='parse-des'>
                            <ul>
                              <li> Metaverse staff training</li>
                              <li> Open Metaverse Club</li>
                              <li> first Vtuber meetup events</li>
                              <li> host exclusive online auction</li>
                              </ul>
                          </div>   
                    </div>
                    </Fade>
                    <Fade bottom>
                    <div className='parse-step mt-5'>
                          <img className='img-fluid parse-img' src={img4} alt="" />
                          <img className='img-fluid mob-img' src={mob4} alt="" />
                          <div className='parse-des'>
                              <ul>
                              <li> Host first online artist exhibition</li>
                              <li> Host first anime concert</li>
                              <li> Offline activity Anime NYC 11/18-11/20</li>
                              </ul>
                          </div>   
                    </div>
                    </Fade>
                    <Fade bottom>
                    <div className='parse-step mt-5'>
                          <img className='img-fluid parse-img' src={img5} alt="" />
                          <img className='img-fluid mob-img' src={mob5} alt="" />
                          <div className='parse-des'>
                             <ul>
                              <li> Deliver Free 3D NFTs</li>
                              <li> 3D NFT presale</li>
                              <li> 3D NFT public sale</li>
                              <li> announce new features for 3D NFT holders</li>
                              <li> Launch 3D NFT giveaway pool</li>
                          </ul>
                          </div>   
                    </div>
                    </Fade>
                    <Fade bottom>
                    <div className='parse-step mt-md-5 last_rd_step'>
                          <img className='img-fluid parse-img' src={img6} alt="" />
                          <img className='img-fluid mob-img' src={mob6} alt="" />
                          <div className='parse-des'>
                              <ul>
                               <li> Launching on-chain Voting platform</li>
                               <li>  more features based on community opinion <br /> to benefit fans and artist</li>
                              </ul>
                          </div>   
                    </div>
                    </Fade>
                </div>
            </Container>
        </div>
    );
};

export default Roadmap;