import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import corner1 from "../../asset/roadmap/leftCorner.png";
import corner2 from "../../asset/roadmap/rightCorner.png";
import desktop2 from "../../asset/roadmap/desktop2.png";
import "./Card.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

const Card = () => {
  return (
    <div>
      <Container>
        <div className="card-background">
          <p className="card-heading fw-bold">Here's how we'll do that:</p>
          <div className="d-md-block d-none">
            <Row className="card-first-row">
              <Col className="col-12 mb-3 col-md-4">
                <div className="card-box p-3">
                  <img className="corner-left" src={corner1} alt="" />
                  <h1 className="heading-num">1.</h1>
                  <p className="guide-description">
                    We’ll provide one-on-one coaching sessions to ACG artists,
                    and teach them how to create their own NFTs. We’ll explain
                    the specifics, and highlight valuable gaps in the market.
                  </p>
                </div>
              </Col>
              <Col className="col-12 mb-3 col-md-4">
                <div className="card-box p-3">
                  <h1 className="heading-num">2.</h1>
                  <p className="guide-description">
                    We’ll use our dedicated following to ensure that our artists
                    are able to grow their following. By hosting virtual and IRL
                    meetups, we’ll provide unique and valuable networking
                    opportunities.
                  </p>
                </div>
              </Col>
              <Col className="col-12 mb-3 col-md-4">
                <div className="card-box p-3">
                  <img className="corner-right" src={corner2} alt="" />
                  <h1 className="heading-num">3.</h1>
                  <p className="guide-description">
                    We’ll launch a scholarship initiative that funds the
                    creation of some amateur projects. Using funds from our own
                    genesis mint, we’ll be able to help promising talent get the
                    recognition they deserve. We’ll use this to encourage more
                    ACG artists to join the space.
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="card-first-row">
              <Col className="col-md-2"></Col>
              <Col className="col-12 mb-3 col-md-4">
                <div className="card-box p-3">
                  <img className="corner-left" src={corner1} alt="" />
                  <h1 className="heading-num">4.</h1>
                  <p className="guide-description">
                    We will hire successful ACG artists to run workshops,
                    teaching less experienced artists valuable lessons about
                    finding their own success.
                  </p>
                </div>
              </Col>
              <Col className="col-12 mb-3 col-md-4">
                <div className="card-box p-3">
                  <img className="corner-right" src={corner2} alt="" />
                  <h1 className="heading-num">5.</h1>
                  <p className="guide-description">
                    We’ll use a portion of our sales revenue to purchase
                    Metaverse land, and move art exhibitions online. We hope to
                    change the culture of ACG artwork presentation, and help our
                    artists to make more sales
                  </p>
                </div>
              </Col>
              <Col className="col-md-2"></Col>
            </Row>
            <div>
              <img className="mt-5 img-fluid pb-5" src={desktop2} alt="" />
            </div>
          </div>
          <div className="d-md-none d-block">
          <Swiper
            slidesPerView={1}
            spaceBetween={24}
            // freeMode={true}
            pagination={{
              clickable: true,
            }}
            // autoplay={{ delay: 3000, disableOnInteraction: false }}
            modules={[Pagination]}
            className="mySwiper pb-3 text_card_slider"
          >
            <SwiperSlide>
              <div className="card-box p-3 m-4">
                <img className="corner-left" src={corner1} alt="" />
                <h1 className="heading-num text-start mt-4 ps-1 text_pr_dark">1.</h1>
                <p className="guide-description">
                  We’ll provide one-on-one coaching sessions to ACG artists, and
                  teach them how to create their own NFTs. We’ll explain the
                  specifics, and highlight valuable gaps in the market.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card-box p-3 m-4">
              <img className="corner-right" src={corner2} alt="" />
                <h1 className="heading-num text-start mt-4 ps-1 text_pr_dark">2.</h1>
                <p className="guide-description">
                  We’ll use our dedicated following to ensure that our artists
                  are able to grow their following. By hosting virtual and IRL
                  meetups, we’ll provide unique and valuable networking
                  opportunities.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card-box p-3 m-4">
              <img className="corner-left" src={corner1} alt="" />
                <h1 className="heading-num text-start mt-4 ps-1 text_pr_dark">3.</h1>
                <p className="guide-description">
                  We’ll launch a scholarship initiative that funds the creation
                  of some amateur projects. Using funds from our own genesis
                  mint, we’ll be able to help promising talent get the
                  recognition they deserve. We’ll use this to encourage more ACG
                  artists to join the space.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card-box p-3 m-4">
               
              <img className="corner-right" src={corner2} alt="" />
                <h1 className="heading-num text-start mt-4 ps-1 text_pr_dark">4.</h1>
                <p className="guide-description">
                  We will hire successful ACG artists to run workshops, teaching
                  less experienced artists valuable lessons about finding their
                  own success.
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card-box p-3 m-4">
                <img className="corner-left" src={corner1} alt="" />
                <h1 className="heading-num text-start mt-4 ps-1 text_pr_dark">5.</h1>
                <p className="guide-description">
                  We’ll use a portion of our sales revenue to purchase Metaverse
                  land, and move art exhibitions online. We hope to change the
                  culture of ACG artwork presentation, and help our artists to
                  make more sales
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Card;
