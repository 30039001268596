import React from "react";
import "./Voting.css";
import voting from "../../asset/image/voting.png";
import { Container } from "react-bootstrap";
import { Fade } from "react-reveal";

// 
const Voting = () => {
  const [showVotingText, setshowVotingText] = React.useState(false)
    const ToggleTextVoting = () =>{
        setshowVotingText(!showVotingText)
    }
  return (
    <div className="chain-voting" id="voting">
      <Container>
        <Fade bottom>
          <div className="logo-part">
            <img className="img-fluid" src={voting} alt="" />
          </div>
        </Fade>
        <Fade bottom>
          <div className="voting-des-box p-3 p-md-5 m-md-5 text-start">
            <p className="voting-des">
              We believe that our NFT holders' opinions will be the basis for
              community growth. Using our specially developed voting system,
              holders can vote on the direction of community development,
              including but not limited to:
            </p>
            <ul className="voting-des">
              <li className="vot-list">● List of Sponsored Artists</li>
              <li className="vot-list">● Interview/meetup Invitation List</li>
              <li className="vot-list">● Online raffle gift selections</li>
              <li className="vot-list">
                ● granting rights to certain groups of specific persons
              </li>
              <li className="vot-list">
                ● Artworks that can be exhibited in our metaverse exhibition
              </li>
            </ul>
              <p className={showVotingText ? "voting-des show-voting-text-active" : "voting-des show-voting-text"}>
                We will continue to regard the opinions of the community as an
                important reference indicator, and allow the holders to truly have
                the full power to participate in the development of the community
                The number of NFTs owned by each member will determine voting
                weight. The more NFTs owned, the higher the influence.
              </p>
              <div className="voting-learn-more">
                <button type="button" className="learn-more" onClick={ToggleTextVoting}>Learn More <i class="fa fa-chevron-down" aria-hidden="true"></i></button>
              </div>
            {/* <ul className='voting-des'>
                    <li className='listing'>Proposers are the only group in the system that can set up on-chain voting.</li>
                    <li className='listing'>The Executors are responsible for executing the voting process and the final results:</li>
                    <li className='listing'>we can assign this role to a person at a particular address to allow it to perform on
                    <li className='listing'>behalf of the original executor. </li>
                        Finally, the Admin role has the power to appoint different roles: this is a crucial and
                        sensitive role</li>
                </ul> */}
            {/* <p className='voting-des'>
                We will then create an application to allow users to vote on-chain and check the result.
                </p> */}
          </div>
        </Fade>
      </Container>
      {/* <a href="#bonus"><button className='scrollTop mt-3'  style={{position:"relative",zIndex:"2"}}>↡</button></a> */}
    </div>
  );
};

export default Voting;
