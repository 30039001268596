import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import eventLogo from "../../asset/metaEvents/eventsLogo.png";
import giveWayLogo from "../../asset/giveway/giveWayLogo.png";
import img1 from "../../asset/giveway/img1.png";
import img2 from "../../asset/giveway/img2.png";
import img3 from "../../asset/giveway/img3.png";
import img4 from "../../asset/giveway/img4.png";
import img5 from "../../asset/giveway/img5.png";
import img6 from "../../asset/giveway/img6.png";

import "./GiveWay.css";
import { Fade } from "react-reveal";

const GiveWay = () => {
  return (
    <div className="giveWay" id="giveaway">
      <div className="wrapper">
        <Container>
          <Fade bottom>
            <div>
              <img className="mt-5 img-fluid pb-4" src={giveWayLogo} alt="" />
              <p className="giveWayHeading">
                Weekly Sweepstakes &amp; Giveaway
              </p>
              <p className="metaHeading fs-5">
                Free NFT of different artists Airdrops
              </p>
              <p className="meta-description mb-5">
                Want to extend your favorite digital art collection?? We will
                cooperate with worldwide talented ACG artists, introduce them to
                our members and also raffle and airdrop their artworks to our
                members.
              </p>
            </div>
          </Fade>
          <div className="mt-5 pb-5">
            <Fade bottom>
              <div className="giveaway-cards-gb">
                <Row xs={1} md={2} className="g-5 mx-0 px-0">
                  <Col>
                    <Card className="eventCard">
                      <Card.Img variant="top" src={img1} />
                    </Card>
                  </Col>
                  <Col>
                    <Card className="eventCard">
                      <Card.Img variant="top" src={img2} />
                    </Card>
                  </Col>
                </Row>
              </div>
            </Fade>
          </div>
          <Fade bottom>
            <div>
              <img className="mt-5" src={eventLogo} alt="" />
              <p className="lotery-text">Weekly Sweepstakes</p>
              <p className="lotery-text fs-6">
                We hold weekly raffles with a variety of gifts:
              </p>
              <div className="coins">
                <img className="img-fluid-coins" src={img3} alt="" />
                <img className="img-fluid-coins" src={img4} alt="" />
                {/* <img className="img-fluid" src={img3} alt="" />
                <img className="img-fluid" src={img4} alt="" /> */}
              </div>
              <div className="coin-des">
                <p>
                  <span className="under-coin">Crypto Currencies:</span> Stable
                  coin / ETH / BNB / Altcoins <br />
                  <span className="under-coin">
                    Online Service digital gift Cards:
                  </span>
                  Amazon/Netflix/Steam etc
                  <br />
                  <span className="under-coin">Tangible Gifts: </span>
                  Mousepad/Power Bank/Hoody/T-Shirt/Cap
                </p>
              </div>
              <div>
                <p className="meta-description fs-6">
                  {/* FREEDEN 2D NFT holders can mint our 3D NFT for free. FREEDEN
                  3D NFTs not only have a unique appearance but also have
                  exclusive utilities and rewards. <br /> */}
                  <br />
                  We hold weekly raffles with a variety of gifts, including
                  cryptocurrencies <br /> <br />
                  Want more unique collections of ACG artworks? We will
                  cooperate with talented artists worldwide, introduce them to
                  our members, and raffle and airdrop their artwork.
                </p>
              </div>
            </div>
          </Fade>
          <Fade bottom>
            <div className="mx-md-5">
              <img className="mt-5" src={eventLogo} alt="" />
              <p className="lotery-text">Free minting FREEDEN 3D NFT</p>
              <p className="give-ways-des coin-des px-2">
                Our early 2D NFT holders can mint our 3D NFT for free. The 3D
                version of FREEDEN NFT is a further promise and appreciation to
                our supporters and ACG communities, which expresses our
                consistent faith in the ACG industry. These 3D NFTs not only
                have higher value, but the holders will have additional member
                benefits! Even better is that the more FREEDEN NFTs (both 2D &
                3D) you hold, the more benefits you get, and the higher winning
                chance you get in future raffle events!"
              </p>
            </div>
          </Fade>
          <div className="mt-5 pb-5 mx-md-5 px-md-4">
            <Fade bottom>
              <div className="giveaway-cards-2-bg">
                <Row xs={1} md={2} className="g-5 mx-0 px-0">
                  <Col>
                    <Card className="eventCard mx-2">
                      <Card.Img variant="top" src={img5} />
                    </Card>
                  </Col>
                  <Col>
                    <Card className="eventCard mx-2">
                      <Card.Img variant="top" src={img6} />
                    </Card>
                  </Col>
                </Row>
              </div>
            </Fade>
          </div>
          <Fade bottom>
            {/* <div className='mx-md-5 pb-5'>
            <img className='mt-5' src={eventLogo} alt="" />
                <p className='lotery-text'>
                       3D Freden NFT giveaways
                </p>
                <p className='give-ways-des coin-des pb-5'>
                    All FreEden holders will get a free Freeden 3D for free. The 3D version of FreEden NFT is a
                    further promise and appreciation to our supporters and ACG communities, which expresses
                    our consistent faith in the ACG industry. These 3D NFTs not only have higher value, but the
                    holders will have additional member benefits! Again for our appreciation to all initial
                    supporters, these NFTs will be distributed to them; they only need to cover the gas fee. Even
                    better is that the more FreDen NFTs (both 2D & 3D) you hold, the more benefits you get, and
                    the higher winning chance you get in future raffle events!
                </p>
            </div> */}
          </Fade>
        </Container>
      </div>
      {/* <a href="#offline"><button className='scrollTop mb-5' style={{position:"relative",zIndex:"2"}}>↡</button></a> */}
    </div>
  );
};

export default GiveWay;
