import React from "react";
import Fade from "react-reveal/Fade";
import aboutUsArt from "../../asset/image/aboutUsArt.png";
import meduim from "../../asset/image/meduimLogo.png";
// import artist from '../../asset/image/artist.png'
import "./AboutUs.css";
const AboutUs = () => {
  return (
    <div id="AboutUs" className="aboutUs">
      
      <img src={aboutUsArt} className="aboutus-img" alt="" />

      <Fade bottom>
        <p className="aboutus-text">
          FREEDEN is an exciting NFT project that seeks to provide a creative
          and immersive experience for ACG artists and enthusiasts across the
          globe. In a world that is making it harder to be creative, most
          artists are struggling for a living while chasing their dreams,
          especially ACG artists, who belong to the neglected and forgotten in
          the art creation group. We’re eager to provide a community where fans
          can collect and share their favorite ACG digital artworks in our
          metaverse club; meanwhile, artists can still make a living with our
          Create to Earn mechanism. By releasing 6,000 generative art NFTs
          designed by ten dedicated artists worldwide, we’ll be able to make
          this come true. We have some exciting and unique opportunities planned
          for each of our holders… Together, we’ll find FREEDEN.
        </p>
      </Fade>
      <Fade bottom>
        <div className="social-section">
          {/* <a href="http://www.facebook.com/freeden.io" target="blank">
            <i class="fa-brands fa-facebook-f"></i>
          </a> */}
          <a href="https://twitter.com/freedenio" target="blank">
            <i class="fa-brands fa-twitter"></i>
          </a>
          <a href="https://www.instagram.com/freeden.io/" target="blank">
            <i class="fa-brands fa-instagram"></i>
          </a>
          <a href="https://discord.gg/rnYW6fu3zv" target="_blank" rel="noopener noreferrer">
            <i class="fa-brands fa-discord"></i>
          </a>
          <a href="https://medium.com/@freeden" target="_blank" rel="noopener noreferrer" >
            <i><img src={meduim} alt="" /></i>
          </a>
        </div>
      </Fade>
    </div>
  );
};

export default AboutUs;
